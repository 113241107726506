import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import PencilLine from "../../../../src/assets/images/Pencil-Line.png";
import { TUserInterest, TAdditionalProfile } from "../../../types/register";
import { TBasicProfileInfo, TProfileMessage } from "./ProfileBasicInfo";
import { RootState } from "../../../state/store";
import { useDispatch, useSelector } from "react-redux";
import axiosClient from "../../../services/axiosInstance";
import {
  TUserState,
  setInterestData,
} from "../../../state/features/loginSlice";

const ProfileAdditionalInfo = ({ data }: TBasicProfileInfo) => {
  const [isEdit, setIsEdit] = useState(false);
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const [interest, setInterest] = useState("");
  const [interestList, setInterestList] = useState<TUserInterest[]>([]);
  const [msg, setMsg] = useState({} as TProfileMessage);
  const { id } = useSelector((state: RootState) => state.user);
  const [AdditionalInfoData, setAdditionalInfoData] =
    useState<TAdditionalProfile>({} as TAdditionalProfile);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setAdditionalInfoData(data.Additional);
      setInterestList(
        data.Additional?.Interest?.map((interst) => {
          return {
            value: interst,
          } as TUserInterest;
        }),
      );
    }
  }, [data.Additional]);

  const onEdit = (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    e.stopPropagation();
    setIsEdit(true);
  };

  const getInterestStringArray = () => {
    let intst = [] as string[];
    for (let i = 0; i < interestList.length; i++) {
      console.log(interestList[i].value);
      intst.push(interestList[i].value);
    }
    return intst;
  };

  const onHandleSubmit = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    try {
      if (!validateAdditionalInfo()) return;
      const res = await client.put("/user", {
        UserId: id,
        Additional: {
          Interest: getInterestStringArray(),
          Expectation: AdditionalInfoData.Expectation,
        },
      });
      if (true) {
        setMsg({ message: "Data saved successfully", isError: false });
        setTimeout(() => {
          setMsg({ message: "" });
        }, 3500);
        setIsEdit(false);

        const profileDataa: TUserState = {
          interest: interestList?.map((interest) => interest.value).join(","),
        };
        dispatch(setInterestData(profileDataa));
      }
    } catch (err: any) {
    } finally {
    }
  };
  const validateAdditionalInfo = (): boolean => {
    if (AdditionalInfoData.Expectation.length === 0) {
      setMsg({ message: "Invalid expectation", isError: true });
      return false;
    }
    if (interestList.length < 5) {
      setMsg({ message: "Add minimum 5 interest", isError: true });
      return false;
    }
    return true;
  };

  const removeInterest = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    index: number,
  ) => {
    e.preventDefault();
    const interests = [...interestList];
    interests.splice(index, 1);
    setInterestList(interests);
    setAdditionalInfoData((prev) => ({
      ...prev,
      Interest: getInterestStringArray(),
    }));
  };

  const onAddInterest = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    const inst: TUserInterest = { value: interest };
    setInterestList([...interestList, inst]);
    setInterest("");
    setAdditionalInfoData((prev) => ({
      ...prev,
      Interest: getInterestStringArray(),
    }));
  };

  return (
    <div>
      <Accordion.Header>
        <div className="acdinNameBox dflex">
          <p className="acdinName"> Addtional Info </p>
          <div className="dflex alignItemCenter">
            {!isEdit ? (
              <>
                <img src={PencilLine} alt="editIcon" />
                <p className="acdinedit" onClick={(e) => onEdit(e)}>
                  Edit
                </p>
              </>
            ) : (
              <p className="acdinedit" onClick={(e) => onHandleSubmit(e)}>
                Save
              </p>
            )}
          </div>
          {msg && msg.message && (
            <div className={msg.isError ? "error-msg" : "success-msg"}>
              <p>{msg.message}</p>
            </div>
          )}
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <form className="MyprofileForm">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <div className="Verify_link">
                    <label> Your Interest </label>
                    <input
                      disabled={!isEdit}
                      type="text"
                      value={interest}
                      onChange={(e) => setInterest(e.target.value)}
                      placeholder={
                        isEdit
                          ? "Your interest (Minimum 5)*"
                          : `${interestList?.map(
                              (interest) => interest.value + " ",
                            )}`
                      }
                    />
                    {interest && interest.trim().length > 0 && (
                      <span>
                        <button
                          className="btn-trans"
                          type="button"
                          onClick={onAddInterest}
                        >
                          Add
                        </button>
                      </span>
                    )}
                    <div className="success-msg">
                      {isEdit &&
                        interestList &&
                        interestList?.map((interest, index) => (
                          <div className="wrapper" key={index}>
                            <p>{interest.value}</p>
                            <span
                              className="close"
                              onClick={(e) => removeInterest(e, index)}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <label> Your expectations </label>
                <div className="textAreaBox">
                  <textarea
                    disabled={!isEdit}
                    rows={4}
                    cols={80}
                    value={AdditionalInfoData?.Expectation}
                    onChange={(e) =>
                      setAdditionalInfoData((prev) => ({
                        ...prev,
                        Expectation: e.target.value,
                      }))
                    }
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12">
                <label> Person For Journey </label>
                <div className="textAreaBox">
                  <textarea
                    disabled={!isEdit}
                    rows={4}
                    cols={80}
                    value={AdditionalInfoData?.Journeyperson}
                    onChange={(e) =>
                      setAdditionalInfoData((prev) => ({
                        ...prev,
                        Expectation: e.target.value,
                      }))
                    }
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Accordion.Body>
    </div>
  );
};

export default ProfileAdditionalInfo;
