export const capitalizeFirstLetter = (
  jsonObj: Record<string, any>,
): Record<string, any> => {
  const modifiedJson: Record<string, any> = {};
  for (const key in jsonObj) {
    const modifiedKey = key.charAt(0).toUpperCase() + key.slice(1);
    modifiedJson[modifiedKey] = jsonObj[key];
  }
  return modifiedJson;
};

export const calculateAge = (date: string) => {
  var today = new Date();
  var birthDate = new Date(date);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const numberInputOnWheelPreventChange = (
  e: React.WheelEvent<HTMLInputElement>,
) => {
  (e.target as HTMLElement).blur();
  e.stopPropagation();
  setTimeout(() => {
    (e.target as HTMLElement).focus();
  }, 0);
};

export const capitalizeNestedJson = (obj: any): any => {
  const result: any = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);

      if (typeof obj[key] === "object" && obj[key] !== null) {
        // If the value is an object, recursively capitalize its keys
        result[capitalizedKey] = capitalizeNestedJson(obj[key]);
      } else if (Array.isArray(obj[key])) {
        // If the value is an array, recursively capitalize keys of each item
        result[capitalizedKey] = obj[key].map((item: any) =>
          typeof item === "object" && item !== null
            ? capitalizeNestedJson(item)
            : item,
        );
      } else {
        // For other types, simply assign the value to the capitalized key
        result[capitalizedKey] = obj[key];
      }
    }
  }

  return result;
};

export const validatePhotoSize = (selectedPhoto: File[]) => {
  const minSize = 100 * 1024;
  const maxSize = 5 * 1024 * 1024;
  if (selectedPhoto) {
    for (let i = 0; i < selectedPhoto.length; i++) {
      if (
        (selectedPhoto[i] && selectedPhoto[i].size > maxSize) ||
        selectedPhoto[i].size < minSize
      ) {
        return true;
      }
    }
  }
  return false;
};

export const daysToReadableFormat = (days: number) => {
  if (days < 0) {
    return 'Lifetime'
  }
  else if (days < 30) {
    return `${days} days`;
  } else if (days < 365) {
    const months = Math.floor(days / 30);
    return `${months} ${months > 1 ? "months" : "month"}`;
  } else {
    const years = Math.floor(days / 365);
    const remainingDays = days % 365;
    if (remainingDays === 0) {
      return `${years} ${years > 1 ? "Years" : "Year"}`;
    } else {
      const months = Math.floor(remainingDays / 30);
      return `${years} ${years > 1 ? "Years" : "Year"} ${months} ${months > 1 ? "months" : "month"}`;
    }
  }
};

export const randomString = () => {
  return (Math.random() + 1).toString(36).substring(7);
};

export function capitalizeFirstLetterofWord(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}
