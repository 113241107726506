import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Header from "../header/HeaderWithLogin";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./Klicks.css";
// import millify from "millify";
import ShowMoreText from "react-show-more-text";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

import { fetchMoreVideos } from "./LoadMoreVideos";
import useSpaceKeyEffect from "./SpaceKeyEvent";
import useWheelScrollEffect from "./ScrollEvent";
import useArrowKeyEffect from "./ArrowKeyEvent";
// import useFetchUserLikesEffect from "./FetchUserLikes";
// import LikeToggle from "./LikeToggle";

import axiosClient from "../../services/axiosInstance";
import volumneIcon from "../../assets/images/VolumeIcon.svg";
import muteIcon from "../../assets/images/muteIcon.svg";
import shareIcon from "../../assets/images/shareIcon.svg";
// import likeIcon from "../../assets/images/likeIcon.svg";
import MainHeader from "../header/HeaderWithoutLogin";
import VideoShareButtons from "./VideoShare";
import InitLoadVideos from "./InitVideos";

import BackArrow from "../../assets/images/cardArrow.svg";

type TLike = {
  _id: string;
  UserId: string;
  CreatedAt: Date;
};

type TVideo = {
  Slug: string;
  _id: string;
  Description: string;
  Url: string;
  Views: number;
  Like: TLike[];
};

const Klicks: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isLoggedIn, id } = useSelector((state: RootState) => state.user);
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const [videos, setVideos] = useState<TVideo[]>([]);
  const [videoPage, setVideoPage] = useState(2);
  const [likeCount, setLikeCount] = useState(0);
  const [likeClass, setLikeClass] = useState("far fa-heart");

  // increment the views on the klick when klick is loaded
  // const incrementViews = async (e: any) => {
  //   navigate(`/klicks/${e.target.id}`, { replace: true });
  //   try {
  //     client.post("/klick/views", {
  //       KlickId: e.target.id,
  //     });
  //   } catch (err: any) { }
  // };

  const vidRef = useRef<HTMLVideoElement>(null);
  const [isVideoPlaying, setisVideoPlaying] = useState<boolean>(false);
  const [pauseButton, setPauseButton] = useState<boolean>(false);
  const [muteFlag, setMuteButton] = useState<boolean>(false);
  const [playIcon, setplayIcon] = useState<boolean>(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0);
  const [lastScrollTime, setLastScrollTime] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  //toggles the video play state. If the video is playing, it pauses the video and shows the play icon.
  //If the video is not playing, it starts playing the video and hides the play icon after a delay.
  const onVideoClick = (): void => {
    let playElements = document.querySelectorAll(".playicon");
    if (isVideoPlaying) {
      setplayIcon(true);
      vidRef.current?.pause();
      setPauseButton(true);
      setisVideoPlaying(false);
      playElements.forEach(function (element) {
        (element as HTMLElement).style.display = "block";
        (element as HTMLElement).style.opacity = "1";
      });
    } else {
      vidRef.current?.play();
      setPauseButton(false);
      setisVideoPlaying(true);
      playElements.forEach(function (playIcon) {
        setTimeout(function () {
          (playIcon as HTMLElement).style.transition = "opacity 0.6s";
          (playIcon as HTMLElement).style.opacity = "0";
        }, 1000);
      });
    }
  };

  useEffect(() => {
    const loadInitialVideos = async () => {
      const loadedVideos = await InitLoadVideos();
      setVideos(loadedVideos);
    };

    loadInitialVideos();
    return () => { };
  }, []);

  // Initially hide the play icon
  const hideIcon = (): void => {
    setplayIcon(false);
  };

  // To toggle the like class of the like icon and also hit the "/like" or "/unlike" API
  // const handleLikeToggle = async (e: any) => {
  //   await LikeToggle(e, id!, likeClass, setLikeClass, setLikeCount);
  // };

  // Toggle the mute button
  const toggleMute = (): void => {
    muteFlag ? setMuteButton(false) : setMuteButton(true);
  };

  const handleScroll = (e: any): void => {
    const delta = Math.sign(e.deltaY),
      delay = 500, // Adjust the delay (in milliseconds) as needed
      now = Date.now();

    if (now - lastScrollTime < delay) {
      return;
    }

    let nextIndex = currentVideoIndex;
    if (
      (videos && delta > 0 && currentVideoIndex < videos.length - 1) ||
      (e.key === "ArrowDown" && currentVideoIndex < videos.length - 1)
    ) {
      nextIndex++;
    } else if (
      (delta < 0 && currentVideoIndex > 0) ||
      (e.key === "ArrowUp" && currentVideoIndex > 0)
    ) {
      nextIndex--;
    }

    // if (nextIndex === 0 && currentVideoIndex === 1) {
    //   return;
    // }

    if (nextIndex !== currentVideoIndex) {
      // Add CSS class for animation
      const videoPlayer = document.getElementById("video-player");
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        videoPlayer?.classList.add(
          e.key === "ArrowDown" ? "scroll-down" : "scroll-up",
        );
      } else {
        videoPlayer?.classList.add(delta > 0 ? "scroll-up" : "scroll-down");
      }

      // Wait for animation to complete before updating current video index
      setCurrentVideoIndex(nextIndex);
      setLastScrollTime(now);
      setPauseButton(false);
      setTimeout(() => {
        videoPlayer?.classList.remove("scroll-up", "scroll-down");
      }, 1500); // Adjust duration to match CSS transition time
      fetchMoreVideos(
        currentVideoIndex,
        videos,
        videoPage,
        setVideos,
        setVideoPage,
      );
    }
  };

  // New state for touch handling
  const touchStartY = useRef<number>(0);
  const touchEndY = useRef<number>(0);

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartY.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    touchEndY.current = e.touches[0].clientY;
  };

  const handleTouchEnd = () => {
    const deltaY = touchStartY.current - touchEndY.current;

    // Check if you're at the first video and trying to swipe backward
    if (deltaY < -50 && currentVideoIndex === 0) {
      return;
    }

    // Check if you're at the last video and trying to swipe forward
    if (deltaY > 50 && currentVideoIndex === videos.length - 1) {
      return;
    }

    // if (deltaY > 50) {
    //   handleScroll({ deltaY: 1 });
    // } else if (deltaY < -50) {
    //   handleScroll({ deltaY: -1 });
    // }
  };

  // Function to handle previous video
  const handlePrevVideo = () => {
    if (currentVideoIndex > 0) {
      setCurrentVideoIndex((prevIndex) => prevIndex - 1);
      setPauseButton(false);
      setisVideoPlaying(false);
    }
  };

  // Function to handle next video
  const handleNextVideo = () => {
    if (currentVideoIndex < videos.length - 1) {
      setCurrentVideoIndex((prevIndex) => prevIndex + 1);
      setPauseButton(false);
      setisVideoPlaying(false);

      // Fetch more videos if nearing the end of the list
      if (currentVideoIndex >= videos.length - 2) {
        fetchMoreVideos(
          currentVideoIndex,
          videos,
          videoPage,
          setVideos,
          setVideoPage,
        );
      }
    }
  };

  // To check current user like the current Klick or not
  // useFetchUserLikesEffect(
  //   videos,
  //   currentVideoIndex,
  //   id!,
  //   setLikeClass,
  //   setLikeCount,
  // );

  // Handle spacebar events to play/pause video
  useSpaceKeyEffect(onVideoClick);

  // Handle scroll events
  // useWheelScrollEffect(handleScroll, currentVideoIndex, videos);

  // Handle arraw up/down key events to next/previous video
  // useArrowKeyEffect(handleScroll, currentVideoIndex, videos);

  return (
    <div
      className="klicksPageSection"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="">
        {isLoggedIn ? <Header /> : <MainHeader />}
        <Helmet>
          <title>Klicks | Premmilan</title>
        </Helmet>
        <div id="video-player" className="video-cards">
          <div className="video-wrapper">
            <div className="pausePlayButton">
              {playIcon && (
                <i
                  className={`playicon fa fa-${pauseButton ? "pause" : "play"}`}
                  aria-hidden="true"
                  style={{ fontSize: 50, color: "white" }}
                ></i>
              )}
            </div>

            <video
              src={videos && videos[currentVideoIndex]?.Url}
              id={videos && videos[currentVideoIndex]?.Slug}
              className="video-player"
              onClick={onVideoClick}
              // onPlay={incrementViews}
              onLoadedData={hideIcon}
              poster="https://premmilan.s3.ap-south-1.amazonaws.com/utils/2226930-videocover.png"
              autoPlay
              loop
              muted={muteFlag}
              ref={vidRef}
              playsInline
            />

            <div className="arrowNavigationBar">
              <img
                className="firstArrow"
                alt="prev video"
                src={BackArrow}
                // onClick={isMobile ? handlePrevVideo : handleNextVideo}
                onClick={handlePrevVideo}
              />
              <img
                className="SecondArrow"
                alt="next video"
                src={BackArrow}
                onClick={handleNextVideo}
                // onClick={isMobile ? handleNextVideo : handlePrevVideo}
              />
            </div>

            <div className="videoOptionPanel">
              {/* <div className="">
                <img
                  src={require("../../assets/images/eyeIcon.png")}
                  style={{ width: 33 }}
                />
                <p>
                  {videos &&
                    millify(
                      videos[currentVideoIndex]
                        ? videos[currentVideoIndex]?.Views
                        : 0,
                    )}
                </p>
              </div> */}
              <div className="klickclick">
                <img
                  src={muteFlag ? muteIcon : volumneIcon}
                  onClick={toggleMute}
                />
              </div>
              {/* <div className="">
                {isLoggedIn ? (
                  <>
                    <a href="javascript:;" onClick={handleLikeToggle}>
                      <i
                        className={likeClass}
                        id={videos && videos[currentVideoIndex]?._id}
                        style={{ fontSize: "35px", color: "red" }}
                      ></i>
                    </a>
                  </>
                ) : (
                  <Link to="/login">
                    <img src={likeIcon} />
                  </Link>
                )}
                <p>{millify(likeCount)}</p>
              </div> */}
              <div className="klickclick">
                <img src={shareIcon} onClick={onOpenModal} />
              </div>
              <Modal
                classNames={{ modal: "myProfileModal mrgn0" }}
                open={open}
                onClose={onCloseModal}
                center
              >
                <VideoShareButtons
                  videoUrl={`${process.env.REACT_APP_BASE_URL}${pathname}`}
                />
              </Modal>
            </div>
            <div className="video-wrapper-content">
              <div key={videos && videos[currentVideoIndex]?.Slug} className="videoDescription">
                <ShowMoreText
                  lines={2}
                  more="Read more"
                  less="Read less"
                  anchorClass=""
                >
                  {videos && videos[currentVideoIndex]?.Description}
                </ShowMoreText>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Klicks;
