import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash"
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import Marquee from "react-fast-marquee";
import { RootState } from "../../state/store";
import Footer from "../Footer";
import Header from "../header/HeaderWithLogin";
import MainHeader from "../header/HeaderWithoutLogin";
import "./Subscription.css";
import axiosClient from "../../services/axiosInstance";
import { daysToReadableFormat } from "../../utils/helper";
import { randomString } from "../../utils/helper";
import { TCoupon } from "../../types/landing";
const fmt = require("indian-number-format");

export type TSubscription = {
  Amount?: number;
  Name: string;
  RequestPerDay?: number;
  Type: string;
  Description: [];
  Validity: number;
  _id: string;
};

export type TCoupons = {
  Code: string;
  CreatedAt: string;
  DiscountAmount: number;
  PackageId: string;
  Status: string;
  Type: string;
  UsedCount: number;
};
export type TSubscriptionContent = {
  Description: string;
  Title: string;
  Video: string;
  _id: string;
};

const Subscription = () => {
  const vidRef = useRef<HTMLVideoElement>(null);
  const { isLoggedIn } = useSelector((state: RootState) => state.user);
  const { packageName, packageId } = useSelector(
    (state: RootState) => state.package,
  );
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const navigate = useNavigate();
  const [userPackage, setUserPackage] = useState<TSubscription[]>(
    [] as TSubscription[],
  );
  const [advPackage, setAdvPackage] = useState<TSubscription[]>(
    [] as TSubscription[],
  );

  const [subContent, setSubContent] = useState<TSubscriptionContent>(
    {} as TSubscriptionContent,
  );
  const [coupons, setCoupons] = useState<TCoupon[]>([] as TCoupon[]);

  const premiumPackages = coupons?.filter(
    (x) => x.PackageName?.toLocaleLowerCase() === "premium"
  );
  const exclusivepackages = coupons?.filter(
    (x) => x.PackageName?.toLocaleLowerCase() === "exclusive"
  );

  const premiumCoupons = premiumPackages?.filter(
    (x) => x.OfferType?.toLocaleLowerCase() === "coupon"
  );
  const premiumReferral = premiumPackages?.filter(
    (x) => x.OfferType?.toLocaleLowerCase() === "referral"
  );
  const exclusiveCoupons = exclusivepackages?.filter(
    (x) => x.OfferType?.toLocaleLowerCase() === "coupon"
  );
  const exclusiveReferral = exclusivepackages?.filter(
    (x) => x.OfferType?.toLocaleLowerCase() === "referral"
  );

  // Premium
  let premiumString = "Premium:- ";
  if (premiumReferral.length > 0) {
    premiumString += `${premiumReferral
      .map((c) => `Referral ${c.DiscountAmount}% off`)
      .join("")} | `;
  }

  if (premiumCoupons.length > 0) {
    premiumString += `Coupon Code: ${premiumCoupons
      .map((c) => `${c.Code?.toUpperCase()} ${c.DiscountAmount}% off`)
      .join(" | ")}`;
  }

  // Exclusive
  let exclusiveString = "Exclusive:- ";
  // if (exclusiveReferral.length > 0) {
  //   exclusiveString += `${exclusiveReferral} |`
  //     // .map((c) => `Referral ${c.DiscountAmount}% off`)
  //     // .join("")} | `;
  // }

  if (exclusiveCoupons.length > 0) {
    exclusiveString += `Coupon Code: ${exclusiveCoupons
      .map((c) => `${c.Code?.toUpperCase()} ${c.DiscountAmount}% off`)
      .join(" | ")}`;
  }


  useEffect(() => {
    async function fetch() {
      try {
        const res = await client.get("/package");
        if (true) {
          let userpkg = (res.data.result as TSubscription[]).filter(
            (x) => x.Type === "User",
          );
          let advpkg = (res.data.result as TSubscription[]).filter(
            (x) => x.Type !== "User",
          );

          userpkg = _.orderBy(userpkg, ['Name'], ['desc']);
          setUserPackage(userpkg);
          advpkg = _.orderBy(advpkg, ['Name'], ['desc']);
          setAdvPackage(advpkg);
        }

        const resContent = await client.get("admin/subscription");
        setSubContent(resContent.data.result);
      } catch (err: any) {
        console.log("error in suscription", err);
      } finally {
      }
    }
    fetch();
  }, []);

  useEffect(() => {
    async function fetchCoupons() {
      try {
        const res = await client.get("/payment/coupons-names");
        setCoupons(res?.data?.result);
      } catch (err: any) {
      } finally {
      }
    }
    fetchCoupons();
  }, []);

  const onHandlePayment = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    packageInfo: TSubscription,
  ) => {
    e.preventDefault();
    navigate("/profile/payment", {
      replace: false,
      state: {
        packageInfo,
      },
    });
  };

  return (
    <div className="SubscriptionPage">
      {isLoggedIn ? <Header /> : <MainHeader />}
      <Helmet>
        <title>Subscription | Premmilan</title>
      </Helmet>

      <section className="SubscriptionSection">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="SubscriptionTopHeader py70">
                <p className="commanheading"> Subscription Packages </p>
              </div>
            </div>
          </div>
        </div>

        <div className="ctaboxmain py70">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="ctaboxtext">
                  <img
                    alt="whitelogo"
                    src={require("../../assets/images/whiteLogo.png")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="SubscriptionPara py70">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="SubscriptionText">
                  <p className="fontMontserrat">{subContent.Description}</p>
                  <p className="textalignCenter gradientText mt40">
                    {subContent.Title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="subpriction_Video_Image">
          <video
            ref={vidRef}
            src={subContent.Video}
            poster="https://premmilan.s3.ap-south-1.amazonaws.com/utils/4801227-subpriction_Video_Image.png"
            autoPlay
            // onClick={onVideoClick}
            loop
            muted
          />
        </div>

        <section className="offerbar py70">
          {
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {
                    <ul>
                      <Marquee speed={50}>
                        {/* <li>{premiumString}</li> */}
                        <li>{exclusiveString}</li>
                      </Marquee>
                    </ul>
                  }
                </div>
              </div>
            </div>
          }
        </section>

        <div className="parentBoxPlanBx">
          <div className="plans_otrBox">
            {userPackage &&
              userPackage.map((pkg, index) => (
                <div className="plansBx" key={randomString()}>
                  <div className="planHaeading">
                    <p> {pkg.Name} </p>
                  </div>
                  <ul>
                    <li>
                      <div>
                        <img
                          className=""
                          src={require("../../assets/images/tick.png")}
                          alt="tickpng"
                        />
                      </div>
                      <p>
                        <span>{daysToReadableFormat(pkg?.Validity)}</span>
                      </p>
                    </li>
                    {pkg?.Description.map((desc: any, key: number) => (
                      <li key={randomString()}>
                        <div>
                          <img
                            className=""
                            src={
                              desc?.Status
                                ? require("../../assets/images/tick.png")
                                : require("../../assets/images/cross.png")
                            }
                            alt="tickpng"
                          />
                        </div>
                        <p>
                          <span>{desc?.Label} </span>
                        </p>
                      </li>
                    ))}

                    <li className="textalignCenter">
                      <button onClick={(e) => onHandlePayment(e, pkg)}>
                        <span> &#8377; {fmt.format(pkg.Amount)} / Year</span>
                        <i className="fa fa-arrow-right" aria-hidden="true"></i>
                      </button>
                      <p className="fontweight700">*Inclusive tax 18% GST </p>
                    </li>
                  </ul>
                </div>
              ))}
          </div>
        </div>

        <div className="SubscriptionTopHeader py70">
          <p className="commanheading"> Paid Advertising Packages </p>
        </div>
        <div className="parentBoxPlanBx">
          <div className="plans_otrBox">
            {advPackage &&
              advPackage.map((pkg, index) => (
                <div className="plansBx" key={randomString()}>
                  <div className="planHaeading">
                    <p> {pkg.Name} </p>
                  </div>
                  <ul>
                    <li key={randomString()}>
                      <div>
                        <img
                          className=""
                          src={require("../../assets/images/tick.png")}
                          alt="tickpng"
                        />
                      </div>
                      <p>
                        <span>{daysToReadableFormat(pkg.Validity)}</span>
                      </p>
                    </li>
                    {pkg?.Description.map((desc: any, key: number) => (
                      <li key={randomString()}>
                        <div>
                          <img
                            className=""
                            src={
                              desc?.Status
                                ? require("../../assets/images/tick.png")
                                : require("../../assets/images/cross.png")
                            }
                            alt="tickpng"
                          />
                        </div>
                        <p>
                          <span>{desc?.Label} </span>
                        </p>
                      </li>
                    ))}
                    <li className="textalignCenter">
                      <Link to="/contact-us">
                        <button>
                          {/* <span> &#8377; 1,25,000 / 6 Months</span> */}
                          <span>Contact Us</span>
                          <i
                            className="fa fa-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </Link>
                      {/* <p className="fontweight700">*Inclusive tax 18% GST </p> */}
                    </li>
                  </ul>
                </div>
              ))}
          </div>
        </div>

        <section className="offerbar py70">
          {
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {
                    <ul>
                      <Marquee speed={50}>
                        {/* <li>{premiumString}</li> */}
                        <li>{exclusiveString}</li>
                      </Marquee>
                    </ul>
                  }
                </div>
              </div>
            </div>
          }
        </section>
      </section>
      <Footer />
    </div>
  );
};

export default Subscription;
