import { useForm } from "react-hook-form";
import { TRegisterInfo } from "../../../types/register";
import { useRegistrationState } from "./RegistrationProvider";
import { RegistrainHeader } from "./RegistrationHeader";
import { numberInputOnWheelPreventChange } from "../../../utils/helper";
import { useEffect, useState } from "react";
import Loader from "../Loader";
import { useLoader } from "../../Root";
import { ToWords } from "to-words";
import { useSelector } from "react-redux";
import axiosClient from "../../../services/axiosInstance";
import { RootState } from "../../../state/store";

export default function EducationWorkForm() {
  const {
    onHandleBack,
    onHandleNext,
    setRegistrationData,
    registrationData,
    stepIndex,
  } = useRegistrationState();
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<TRegisterInfo>({
    defaultValues: registrationData,
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });
  const { token } = useSelector((state: RootState) => state.auth);
  const { name } = useSelector((state: RootState) => state.user);

  const client = axiosClient(token);
  const income = watch("educationWork.work.exactIncome");
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: true,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //const { isLoading, setIsLoading } = useLoader();
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState<string | null>();

  const onHandleFormSubmit = async (data: TRegisterInfo) => {
    setIsLoading(true);
    setRegistrationData((prevData) => ({
      ...prevData,
      ...data,
    }));
    try {
      setIsLoading(true);
      await client.post("/steps", {
        Email: name,
        step: stepIndex,
        educationWorkSchema: {
          Education: {
            EducationName: data?.educationWork?.education?.educationName,
            CollegeName: data?.educationWork?.education?.collegeName,
            PassingYear: data?.educationWork?.education?.passingYear,
          },
          Work: {
            Occupation: data?.educationWork?.work?.occupation,
            CompanyName: data?.educationWork?.work?.companyName,
            WorkTitle: data?.educationWork?.work?.workTitle,
            ExactIncome: data?.educationWork?.work?.exactIncome,
          },
        },
      });
      if (true) {
        onHandleNext();
        localStorage.setItem("stepIndex", (stepIndex + 1).toString());
      }
    } catch (err: any) {
      if (err.response) {
        setErr(err.response.data?.error);
      }
    } finally {
      setIsLoading(false);
    }
    // onHandleNext();
    // setIsLoading(false);
  };

  if (isLoading) return <Loader />;

  return (
    <div>
      <div className="EducationWorkfrom">
        <RegistrainHeader />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="progress-desktop" id="progressbar">
                <li className="active" id="account">
                  <i className="fa fa-heart complete"></i>
                </li>
                <li id="personal">
                  <i className="fa fa-heart"></i>
                </li>
                <li id="confirm">
                  <i className="fa fa-heart"></i>
                </li>
                <li id="end">
                  <i className="fa fa-heart"></i>
                </li>
              </ul>
              <ul
                className="progress-mobile"
                style={{ display: "none" }}
                id="progressbar"
              >
                <li className="active" id="account">
                  <i className="fa fa-heart complete"></i>
                </li>
                <li id="personal">
                  <i className="fa fa-heart complete"></i>
                </li>
                <li id="payment">
                  <i className="fa fa-heart complete"></i>
                </li>
                <li id="confirm">
                  <i className="fa fa-heart complete"></i>
                </li>
                <li id="end">
                  <i className="fa fa-heart complete"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="detail-title">
              <div className="reg-icon">
                <img
                  src={require("../../../assets/images/education.png")}
                  alt="user"
                />
              </div>
              <div className="prsl-detail-heading">Education</div>
            </div>

            <div className="row">
              <div className="col-md-1"></div>

              <div className="col-md-11">
                <div className="">
                  <div className="prsl-detail">
                    <form
                      id="msform"
                      onSubmit={handleSubmit(onHandleFormSubmit)}
                    >
                      <fieldset>
                        <div className="form-card">
                          <div className="reg-forms">
                            <input
                              {...register(
                                "educationWork.education.educationName",
                                {
                                  required: "Required*",
                                }
                              )}
                              id="educationname"
                              type="text"
                              style={{ textTransform: "capitalize" }}
                              placeholder="Higher education/Degree name*"
                            />
                            {errors.educationWork?.education?.educationName && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {
                                  errors.educationWork?.education?.educationName
                                    .message
                                }
                              </div>
                            )}
                            <div className="dflex space">
                              <div>
                                <input
                                  {...register(
                                    "educationWork.education.collegeName",
                                    {
                                      required: "Required*",
                                    }
                                  )}
                                  id="collegename"
                                  type="text"
                                  style={{ textTransform: "capitalize" }}
                                  placeholder="College/University*"
                                />
                                {errors.educationWork?.education
                                  ?.collegeName && (
                                  <div className="error-msg">
                                    <i className="fa fa-exclamation-triangle"></i>
                                    {
                                      errors.educationWork?.education
                                        ?.collegeName.message
                                    }
                                  </div>
                                )}
                              </div>
                              <div>
                                <input
                                  {...register(
                                    "educationWork.education.passingYear",
                                    {
                                      required: "Required*",
                                      validate: (value) => {
                                        if (
                                          value < 1950 ||
                                          value > new Date().getFullYear()
                                        )
                                          return "Invalid passing year";

                                        if (
                                          value <
                                          new Date(
                                            registrationData?.personal?.dob
                                          ).getFullYear()
                                        ) {
                                          return "Invalid passing year";
                                        }
                                        return true;
                                      },
                                    }
                                  )}
                                  id="passingyear"
                                  type="number"
                                  onWheel={numberInputOnWheelPreventChange}
                                  placeholder="Passing Year*"
                                />
                                {errors.educationWork?.education
                                  ?.passingYear && (
                                  <div className="error-msg">
                                    <i className="fa fa-exclamation-triangle"></i>
                                    {
                                      errors.educationWork?.education
                                        ?.passingYear.message
                                    }
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="border"></div>
                          </div>
                          <div className="detail-title mln50">
                            <div className="reg-icon">
                              <img
                                src={require("../../../assets/images/work.png")}
                                alt="work"
                              />
                            </div>
                            <div className="prsl-detail-heading">Work</div>
                          </div>
                          <div className="reg-forms">
                            <input
                              {...register("educationWork.work.occupation", {
                                required: false,
                              })}
                              id="occupation"
                              type="text"
                              style={{ textTransform: "capitalize" }}
                              placeholder="Occupation"
                            />
                            {errors.educationWork?.work?.occupation && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {errors.educationWork?.work?.occupation.message}
                              </div>
                            )}
                            <div className="dflex space">
                              <div>
                                <input
                                  {...register(
                                    "educationWork.work.companyName",
                                    {
                                      required: "Required*",
                                    }
                                  )}
                                  id="companyname"
                                  type="text"
                                  style={{ textTransform: "capitalize" }}
                                  placeholder="Company name*"
                                />
                                {errors.educationWork?.work?.companyName && (
                                  <div className="error-msg">
                                    <i className="fa fa-exclamation-triangle"></i>
                                    {
                                      errors.educationWork?.work?.companyName
                                        .message
                                    }
                                  </div>
                                )}
                              </div>
                              <div>
                                {" "}
                                <input
                                  {...register("educationWork.work.workTitle", {
                                    required: "Required*",
                                  })}
                                  id="worktitle"
                                  type="text"
                                  style={{ textTransform: "capitalize" }}
                                  placeholder="Work title*"
                                />
                                {errors.educationWork?.work?.workTitle && (
                                  <div className="error-msg">
                                    <i className="fa fa-exclamation-triangle"></i>
                                    {
                                      errors.educationWork?.work?.workTitle
                                        .message
                                    }
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="exact_income">
                              <input
                                {...register("educationWork.work.exactIncome", {
                                  required: false,

                                  validate: (value) => {
                                    const hasDecimal =
                                      value.toString().split(".").length > 1;
                                    if (
                                      hasDecimal ||
                                      value.toString().endsWith(".")
                                    )
                                      return "Invalid income";
                                    return true;
                                  },
                                })}
                                onWheel={numberInputOnWheelPreventChange}
                                type="number"
                                id="exactincome"
                                placeholder="Annual Income"
                              />
                              {income && toWords.convert(income)}
                              {errors.educationWork?.work?.exactIncome && (
                                <div className="error-msg">
                                  <i className="fa fa-exclamation-triangle"></i>
                                  {
                                    errors.educationWork?.work?.exactIncome
                                      .message
                                  }
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="reg-forms">
                          <div className="divider"></div>
                          <div className="btn-flex">
                            <div className="left-btn">
                              <button
                                className="btn-trans"
                                type="button"
                                onClick={onHandleBack}
                              >
                                <i className="fa-solid fa-arrow-left"></i> Back
                              </button>
                            </div>
                            <button
                              disabled={isSubmitting}
                              type="submit"
                              className="cont-btn commonBtn"
                            >
                              Continue
                              <i className="fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </div>
  );
}
