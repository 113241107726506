import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { TRegister } from "../../../types/auth";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Profiles } from "../../../utils/constants";
import * as constant from "../../../utils/constants";
import {
  setRegisterData,
  TRegisterState,
  resetRegisger,
} from "../../../state/features/registerSlice";
import axiosClient from "../../../services/axiosInstance";
import { RootState } from "../../../state/store";
import Loader from "../Loader";
import AuthLeftBanner from "../../AuthLeftBanner_compo";
import PasswordStrengthBar from "react-password-strength-bar";
import { setAuth } from "../../../state/features/authSlice";
import { setUser, TUserState } from "../../../state/features/loginSlice";
import { signInWithEmailAndPassword } from "firebase/auth";
import { setFirebaseUser } from "../../../state/features/firebaseSlice";
import { auth } from "../../../services/firebase";
import { setPackageData, TPackageState } from "../../../state/features/packageSlice";

const Register = () => {
  //const { isLoading, setIsLoading } = useLoader();
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isOtp, setOtp] = useState(false);
  const [invalidOtpErr, setinvalidOtpErr] = useState<string | null>();
  const [errSendOtp, setErrSendOtp] = useState<string | null>();
  const { email } = useSelector((state: RootState) => state.register);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<TRegister>({
    defaultValues: {
      email: "",
      password: "",
      profileManagedBy: "",
      partnerPreference: "",
      profileFor: "",
    },
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    dispatch(resetRegisger());
  }, []);

    const handleChatLogin = async (email: string, password: string) => {
      try {
        const currentUser = await signInWithEmailAndPassword(
          auth,
          email.toLowerCase(),
          password
        );
        dispatch(setFirebaseUser({ currentUser }));
      } catch (err) {}
    };

  const onhandleFormSubmit = async (data: TRegister) => {
    if (isOtp) {
      try {
        setIsLoading(true);
        const res = await client.post("/verifyEmailOTP", {
          Email: email,
          OTP: data.otp,
        });
        setIsLoading(false);
        if (true) {
          // if (true) {
            const token = res.data.jwt;
            await handleChatLogin(data.email, data.password);
            dispatch(setAuth({ token }));
            console.log("enter in login 43");
            // console.log(userData)
            const user: TUserState = {
              id: res.data.Id,
              name: res.data.Email,
              isLoggedIn: true,
              isRegistrationIncomplete:res.data.isRegistrationIncomplete,
              password: data.password
            };
            console.log("user")
            dispatch(setUser(user));
            // const clientWithToken = axiosClient(token);
            // console.log(clientWithToken)
            // try {
            //   const packageRes = await clientWithToken.get(
            //     `/user/package/${res.data.Id}`
            //   );
            //   if (true) {
            //     const pkg: TPackageState = {
            //       expireDate: packageRes.data.result[0].ExpireAt,
            //       packageName: packageRes.data.result[0].Details.Name,
            //       requestPerDay: packageRes.data.result[0].Details.RequestPerDay,
            //       packageId: packageRes.data.result[0].PackageId,
            //     };
            //     dispatch(setPackageData(pkg));
            //   }
            // } catch (err: any) {
            //   console.log("error while fetching package info");
            // }
            // navigate("/registration", { replace: true });
            console.log("object")
          // }
          setinvalidOtpErr(null);
          navigate("/registrationsuccess", { replace: true });
          return;
        }
      } catch (err: any) {
        if (err.response) {
          setinvalidOtpErr(err.response.data?.error);
        } else {
          setinvalidOtpErr("Error while verifying OTP");
        }
      } finally {
        setIsLoading(false);
        return;
      }
    }

    console.log(data)

    try {
      setIsLoading(true);
      const response = await client.post("/sendEmailOTP", {
        Email: data.email,
        Password: data.password,
        ProfileFor: data.profileFor,
        ProfileManagedBy: data.profileManagedBy,
        PartnerPreference: data.partnerPreference,
      });
      setIsLoading(false);
      if (true) {
        const regData: TRegisterState = {
          email: data.email,
          password: data.password,
          profileFor: data.profileFor,
          profileManagedBy: data.profileManagedBy,
          partnerPreference: data.partnerPreference,
        };

        setTimeout(() => {
          setErrSendOtp(null);
        }, 3000);
        dispatch(setRegisterData(regData));
        setOtp(true);
      }
    } catch (err: any) {
      if (err.response) {
        setErrSendOtp(err.response.data?.error);
      } else {
        setErrSendOtp("Error while sending OTP");
      }
    } finally {
      setIsLoading(false);
      return;
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="registerPage">
      <Helmet>
        <title>Register | Premmilan</title>
      </Helmet>
      <div className="login_form">
        <AuthLeftBanner
          imgName="https://premmilan.s3.ap-south-1.amazonaws.com/utils/9580312-registration-banner.png"
          altName="registration"
        />
        <div className="login_right_sec">
          <div className="innr_frm">
            <div className="login-logo">
              <Link to="/">
                <img
                  src={require("../../../assets/images/logo.png")}
                  alt="logo"
                />
              </Link>
            </div>
            <div className="login-form">
              <h1>Register Free</h1>
              <form onSubmit={handleSubmit(onhandleFormSubmit)}>
                <div>
                  {!isOtp && (
                    <div>
                      <input
                        id="email"
                        {...register("email", {
                          required: "Email is required",
                          validate: (value) => {
                            const regx =
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            return !regx.test(value) ? "invalid email" : true;
                          },
                        })}
                        placeholder="Your email address*"
                        type="text"
                      />
                      {errors.email && (
                        <div className="error-msg">
                          <i className="fa fa-exclamation-triangle"></i>{" "}
                          {errors.email.message}
                        </div>
                      )}
                      <div>
                        <div className="password-container">
                          <input
                            className="starlabel"
                            id="password"
                            {...register("password", {
                              required: "Password is required",
                              validate: (value) => {
                                if (value && value?.length! < 8)
                                  return "Password must be of minimum 8 characters";
                                return true;
                              },
                            })}
                            placeholder="Create new password*"
                            type={showPassword ? "text" : "password"}
                          />

                          <span
                            className={
                              showPassword
                                ? "fa fa-fw fa-eye field_icon toggle-password"
                                : "fa fa-fw fa-eye-slash field_icon toggle-password"
                            }
                            onClick={toggleShowPassword}
                          ></span>
                        </div>
                        {errors.password && (
                          <div className="error-msg">
                            <i className="fa fa-exclamation-triangle"></i>{" "}
                            {errors.password.message}
                          </div>
                        )}
                        {watch("password") && (
                          <PasswordStrengthBar password={watch("password")!} />
                        )}
                      </div>
                      <div>
                        <select
                          className="dropdown-menu"
                          defaultValue=""
                          {...register("profileFor", {
                            required: "Please select profile",
                            //disabled: isOtp,
                          })}
                        >
                          <option value="" disabled>
                            Create profile for
                          </option>
                          {Profiles.map((profile) => (
                            <option key={profile.id}>{profile.value}</option>
                          ))}
                        </select>
                        {errors.profileFor && (
                          <div className="error-msg">
                            <i className="fa fa-exclamation-triangle"></i>
                            {errors.profileFor.message}
                          </div>
                        )}
                      </div>
                      <div>
                        <select
                          className="dropdown-menu"
                          defaultValue=""
                          {...register("profileManagedBy", {
                            required: "Please select profile manager",
                            //disabled: isOtp,
                          })}
                        >
                          <option value="" disabled>
                            Profile Managed By
                          </option>
                          {constant.PROFILE_MANAGED_BY.map((profile) => (
                            <option key={profile.id}>{profile.value}</option>
                          ))}
                        </select>
                        {errors.profileManagedBy && (
                          <div className="error-msg">
                            <i className="fa fa-exclamation-triangle"></i>
                            {errors.profileManagedBy.message}
                          </div>
                        )}
                      </div>
                      <div>
                        <select
                          className="dropdown-menu"
                          defaultValue=""
                          {...register("partnerPreference", {
                            required: "Select looking for",
                            //disabled: isOtp,
                          })}
                        >
                          <option value="" disabled>
                            Looking for
                          </option>
                          {constant.GENDERS.map((gender) => (
                            <option key={gender.id} value={gender.value}>
                              {gender.value}
                            </option>
                          ))}
                        </select>
                        {errors.partnerPreference && (
                          <div className="error-msg">
                            <i className="fa fa-exclamation-triangle"></i>
                            {errors.partnerPreference.message}
                          </div>
                        )}
                        {errSendOtp && (
                          <div className="error-msg">
                            <i className="fa fa-exclamation-triangle"></i>
                            {errSendOtp}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {isOtp && (
                  <div className="registrationFree">
                    <input
                      id="otp"
                      {...register("otp", {
                        required: "Please enter the OTP",
                      })}
                      placeholder="Enter OTP"
                      type="number"
                      autoFocus
                    />
                    {invalidOtpErr && (
                      <div className="error-msg">
                        <i className="fa fa-exclamation-triangle"></i>
                        {invalidOtpErr}
                      </div>
                    )}
                  </div>
                )}
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="loginbtn"
                >
                  {isOtp ? "Proceed" : "Send OTP"}
                  <span>
                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                  </span>
                </button>
                {isOtp && (
                  <div className="registrationFreeViewBtn">
                    <button className="btn-trans" onClick={() => setOtp(false)}>
                      Resend OTP
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
          <div className="divider"></div>
          <div className="login-regstr">
            <p>
              Already have an account?&nbsp;
              <Link to="/login">
                <span className="color">Login</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
