import { useDispatch, useSelector } from "react-redux";
import { TRegisterInfo, TUserInterest } from "../../../types/register";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import axiosClient from "../../../services/axiosInstance";
import { RootState } from "../../../state/store";
import {
  setRegisterData,
  TRegisterState,
} from "../../../state/features/registerSlice";
import { useNavigate } from "react-router-dom";
type TRegistrationContextProp = {
  children: ReactNode;
};

type TRegistrationContext = {
  onHandleNext: () => void;
  onHandleBack: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  stepIndex: number;
  onSetInterest: (interest: TUserInterest[]) => void;
  interestList: TUserInterest[];
  recordedVideo: Blob;
  onSetRecordedVideo: (video: Blob) => void;
  photos: File[];
  onSetPhotos: (photos: File[]) => void;
  registrationData: TRegisterInfo;
  setRegistrationData: React.Dispatch<React.SetStateAction<TRegisterInfo>>;
};

const RegistrationContext = createContext<TRegistrationContext>(
  {} as TRegistrationContext
);

export default function RegistrationProvider({
  children,
}: TRegistrationContextProp) {
  const { token } = useSelector((state: RootState) => state.auth);
  console.log(token);
  const client = axiosClient(token);
  const [stepIndex, setStepIndex] = useState(1);
  const dispatch = useDispatch();
  const [recordedVideo, setRecordedVideo] = useState<Blob>(new Blob());
  const [interestList, setInterestList] = useState<TUserInterest[]>([]);
  const [photos, setPhotos] = useState<File[]>([]);
  const [registrationData, setRegistrationData] = useState({} as TRegisterInfo);
  const navigate = useNavigate();

  console.log(registrationData);

  function onHandleNext() {
    setStepIndex((prev) => {
      return prev + 1;
    });
  }

  useEffect(() => {
    async function getBlogs() {
      try {
        const res = await client.get(`/current-steps`);
        if (true) {
          // setBlogResult(res?.data?.result);
          console.log(res?.data);
          setStepIndex(res?.data?.CurrentStep);
          setRegistrationData({
            personal: {
              ...res?.data?.PersonalSchema,
              dob: res?.data?.PersonalSchema?.dob?.slice(0, 10),
            },
            educationWork: {
              education: res?.data?.EducationWork?.education,
              work: res?.data?.EducationWork?.work,
            },
            address: {
              currentAddress: res?.data?.CurrentAddress,
              permanentAddress: res?.data?.PermanentAddress,
            },
            family: res?.data?.Family,
            additional: res?.data?.AdditionalSchema,
          });
          const regData: TRegisterState = {
            email: res?.data.Email,
            password: res?.data.Password,
            profileFor: res?.data.ProfileFor,
            profileManagedBy: res?.data.ProfileManagedBy,
            partnerPreference: res?.data.PartnerPreference,
          };

          dispatch(setRegisterData(regData));

          localStorage.setItem("stepIndex", res?.data?.CurrentStep);
          localStorage.setItem("isMobileVerified", res?.data?.IsMobileVerified);
        } else {
          
          console.log("object wla aa gya");
        }
      } catch (err: any) {
        navigate("/profile", { replace: true });

      } finally {
        console.log("false");

      }
    }
    getBlogs();
  }, []);

  function onSetInterest(interest: TUserInterest[]) {
    setInterestList(interest);
  }

  function onSetRecordedVideo(video: Blob) {
    setRecordedVideo(video);
  }

  function onSetPhotos(photos: File[]) {
    setPhotos(photos);
  }

  function onHandleBack(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    setStepIndex((prev) => {
      return prev - 1;
    });
  }

  console.log("object");

  return (
    <RegistrationContext.Provider
      value={{
        photos,
        onSetPhotos,
        interestList,
        onSetInterest,
        recordedVideo,
        onSetRecordedVideo,
        stepIndex,
        registrationData,
        setRegistrationData,
        onHandleNext,
        onHandleBack,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
}

export function useRegistrationState() {
  return useContext(RegistrationContext);
}
