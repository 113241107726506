import { useSelector } from "react-redux";
import loading3icon from "../../assets/images/loading3icon.svg";
import { RootState } from "../../state/store";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function RegistrationSuccess() {
  const { email } = useSelector((state: RootState) => state.register);
  const navigate = useNavigate();
  useEffect(() => {
    if (!email) {
      navigate("/register", { replace: true });
      return;
    }
  }, []);
  return (
    <div>
      <div className="fullbodyLoaderBgColor">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="justifyContentCenter dflex alignItemCenter vh100">
                <img src={loading3icon} />
              </div>
            </div>
          </div>
        </div>
        <p className="ftrText"> Registration successfully! <Link to={'/registration'}>Click here for Login !</Link> </p>
        
      </div>
    </div>
  );
}
